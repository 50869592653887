








































































































































































import { SearchBuilder } from "@/builder";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { toTitlecase, trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import {
  APagination,
  Row,
  useAsset,
  useBlob,
  useContactData,
  useDate,
  useFindMasterType,
  useMapMasterTypeToOptionAlt,
  useSort,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { truckingSettlementDetailReportService } from "@/services/trucking-settlement-detail-report.service";
import { ColumnDef, LabelInValue, SorterProps } from "@/types";
import {
  TruckingSettlementReportDataView,
  TruckingSettlementReportDto,
  TruckingSettlementReportHeaderDto,
  TruckingSettlementReportState,
} from "@interface/trucking-settlement-report";
import { FormModel } from "ant-design-vue";
import Vue from "vue";

export default Vue.extend({
  name: "SettlementDetailReport",
  components: {},
  data() {
    this.onSearchCustomer = debounceProcess(this.onSearchCustomer, 500);
    this.onSearchTruck = debounceProcess(this.onSearchTruck, 500);

    const baseColumns = [
      {
        title: this.$t("lbl_customer"),
        dataIndex: "customer",
        key: "customerName",
        fixed: "left",
        sorter: true,
        width: 250,
      },
      {
        title: this.$t("lbl_unit_code"),
        dataIndex: "unitCode",
        fixed: "left",
        sorter: true,
        width: 250,
      },
      {
        title: this.$t("lbl_settlement_date"),
        dataIndex: "settlementDate",
        customRender: (text: string) => dateFormat(text),
        sorter: true,
      },
      {
        title: this.$t("lbl_shipment_id"),
        dataIndex: "shipmentId",
        sorter: true,
      },
      {
        title: this.$t("lbl_ujo_number"),
        dataIndex: "ujoNumber",
        sorter: true,
      },
      {
        title: this.$t("lbl_sales_name"),
        dataIndex: "salesName",
        customRender: (text: string) => text || "-",
      },
      {
        title: this.$t("lbl_description"),
        dataIndex: "description",
        customRender: (text: string) => text || "-",
      },
      {
        title: this.$t("lbl_status"),
        dataIndex: "status",
        customRender: (text: string) => toTitlecase(text),
      },
    ] as ColumnDef[];

    return {
      PAGE_SIZE_OPTIONS,
      DEFAULT_DATE_FORMAT,
      state: {
        customer: undefined,
        settlementDate: null,
        shipment: undefined,
        status: undefined,
        ujo: undefined,
        unitCode: undefined,
      } as TruckingSettlementReportState,
      dataReport: {
        totalElements: 0,
        currentPage: 0,
        data: [],
        totalPages: 0,
        total: 0,
      } as TruckingSettlementReportHeaderDto,
      loading: {
        find: false,
        download: false,
        customer: false,
        unitCode: false,
        status: false,
      },
      pagination: {
        page: DEFAULT_PAGE,
        limit: DEFAULT_PAGE_SIZE,
      },
      customerOptions: [] as Option[],
      truckOptions: [] as Option[],
      statusOptions: [] as Option[],
      dataSource: [] as Row<TruckingSettlementReportDataView, string>[],
      baseColumns: baseColumns,
      columns: [...baseColumns] as Partial<ColumnDef>[],
    };
  },
  mounted() {
    this.getCustomerList();
    this.getTruckList();
    this.getStatusList();
  },
  methods: {
    toListParams(
      state: TruckingSettlementReportState,
      {
        limit,
        page,
        sorts = "customerName:asc",
      }: { limit: number; page: number; sorts?: string }
    ): RequestQueryParams {
      const { toStartDay, toEndDay } = useDate();
      const queries: string[] = [];

      if (state.ujo) {
        const searchByUjo = new SearchBuilder()
          .push(["ujoNumber", state.ujo], { like: "both" })
          .build();
        queries.push(searchByUjo);
      }

      if (state.shipment) {
        const searchByShipment = new SearchBuilder()
          .push(["shipmentId", state.shipment], { like: "both" })
          .build();
        queries.push(searchByShipment);
      }

      if (state.customer) {
        const searchByCustomer = new SearchBuilder()
          .push(["customerId", state.customer.key])
          .build();
        queries.push(searchByCustomer);
      }

      if (state.settlementDate && state.settlementDate.length === 2) {
        const [start, end] = state.settlementDate;
        const searchBySettlementDate = new SearchBuilder()
          .push(["settlementDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["settlementDate", toEndDay(end).format()], { let: true })
          .build();
        queries.push(searchBySettlementDate);
      }

      if (state.unitCode && state.unitCode.length > 0) {
        const unitCodes = state.unitCode
          .map(item => {
            const searchByUnitCode = new SearchBuilder()
              .push(["unitCode", item.label])
              .build();
            return searchByUnitCode;
          })
          .join(SearchBuilder.OR);
        queries.unshift(unitCodes);
      }

      if (state.status) {
        const searchByStatus = new SearchBuilder()
          .push(["status", state.status])
          .build();
        queries.push(searchByStatus);
      }

      const params = new RequestQueryParams();
      params.search = queries.join(SearchBuilder.AND);
      params.limit = limit;
      params.page = page - ONE;
      params.sorts = sorts;

      return params;
    },
    toReportDataView(
      data: TruckingSettlementReportDto
    ): Row<TruckingSettlementReportDataView, string> {
      const accounts = {
        ...data.detailSettlement,
      };
      return {
        key: generateUUID(),
        customer: data.customerName,
        unitCode: data.unitCode,
        settlementDate: data.settlementDate,
        shipmentId: data.shipmentId,
        ujoNumber: data.ujoNumber,
        salesName: data.sales,
        description: data.description,
        status: data.status,
        journalNumber: data.journalNumber,
        subtotal: data.settlementTotal,
        ...accounts,
      };
    },
    buildColumns(data: TruckingSettlementReportDto[]) {
      const columns: Partial<ColumnDef>[] = [...this.baseColumns];
      const accounts = new Set<string>();

      for (const settlement of data) {
        for (const account in settlement.detailSettlement) {
          accounts.add(account);
        }
      }

      const { sortStr } = useSort();
      const sortedAccounts = [...accounts.values()].sort(sortStr);

      for (const account of sortedAccounts) {
        columns.push({
          title: account,
          dataIndex: account,
          align: "right",
          customRender: text => currencyFilter(text),
        });
      }

      columns.push({
        title: this.$t("lbl_subtotal"),
        dataIndex: "subtotal",
        align: "right",
        customRender: text => currencyFilter(text),
      });

      return columns;
    },
    async getReport(params?: RequestQueryParamsModel) {
      try {
        this.loading.find = true;
        const response = await truckingSettlementDetailReportService.getList(
          params
        );
        this.dataReport = response;
        this.dataSource = response.data.map<
          Row<TruckingSettlementReportDataView, string>
        >(this.toReportDataView);
        this.columns = this.buildColumns(response.data);
      } finally {
        this.loading.find = false;
      }
    },
    handleSubmit() {
      const params: RequestQueryParamsModel = this.toListParams(this.state, {
        page: this.pagination.page,
        limit: this.pagination.limit,
      });
      this.getReport(params);
    },
    onChangeTable(
      { current, pageSize }: APagination,
      _filter,
      { columnKey, order }: SorterProps
    ) {
      const { toOrder } = useSort();
      this.pagination.page = current;
      if (pageSize !== this.pagination.limit) {
        this.pagination.page = DEFAULT_PAGE;
      }
      this.pagination.limit = pageSize;

      const params: RequestQueryParamsModel = this.toListParams(this.state, {
        page: this.pagination.page,
        limit: this.pagination.limit,
        sorts: order ? `${columnKey}:${toOrder(order)}` : undefined,
      });
      this.getReport(params);
    },
    toDownloadParams(
      state: TruckingSettlementReportState
    ): RequestQueryParamsModel {
      const params: RequestQueryParams = this.toListParams(state, {
        limit: this.dataReport.totalElements || 1,
        page: DEFAULT_PAGE,
      });
      const company =
        this.$store.state.authStore.authData.companyName ??
        "PT. SATRIA PIRANTI PERKASA";

      let settlementDate = "ALL";
      if (state.settlementDate && state.settlementDate.length === 2) {
        const [start, end] = state.settlementDate;
        const startSettlementDate = start.format(DEFAULT_DATE_FORMAT);
        const endSettlementDate = end.format(DEFAULT_DATE_FORMAT);
        settlementDate = `${startSettlementDate} - ${endSettlementDate}`;
      }

      let unitCodes = "ALL";
      if (state.unitCode) {
        unitCodes = state.unitCode?.map(item => item.label).join(" & ");
      }

      let customer = "ALL";
      if (state.customer) {
        customer = state.customer.label.replace(",", "");
      }

      const headerReport: string = [
        company,
        customer,
        state.ujo || "ALL",
        state.shipment || "ALL",
        settlementDate,
        unitCodes,
        state.status || "ALL",
      ].join(",");

      params.params = headerReport;
      return params;
    },
    async handleDownload() {
      const { toDownload } = useBlob();
      try {
        const params = this.toDownloadParams(this.state);
        this.loading.download = true;
        const response = await truckingSettlementDetailReportService.download(
          params
        );
        toDownload(response, "trucking_settlement_detail_report.xlsx");
      } finally {
        this.loading.download = false;
      }
    },
    async getCustomerList(
      params: RequestQueryParamsModel = new RequestQueryParams()
    ) {
      const { findCustomers, toOptions } = useContactData();
      try {
        this.loading.customer = true;
        const response = await findCustomers(params);
        this.customerOptions = toOptions(response.data);
      } finally {
        this.loading.customer = false;
      }
    },
    onSearchCustomer(value?: string) {
      const { filterBy } = useContactData();
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ firstName: value, lastName: value });
      }
      this.getCustomerList(params);
    },
    async getTruckList(params?: RequestQueryParamsModel) {
      const { findAllTruck, toOptionsNew } = useAsset();
      try {
        this.loading.unitCode = true;
        const response = await findAllTruck(params);
        this.truckOptions = toOptionsNew(response.data);
      } finally {
        this.loading.unitCode = false;
      }
    },
    async onSearchTruck(value?: string) {
      const { filterBy } = useAsset();
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ unitCode: value });
      }
      this.getTruckList(params);
    },
    async getStatusList() {
      const status = ["APPROVED", "POSTED"];
      try {
        this.loading.status = true;
        const response = await useFindMasterType("TRUCKING_SETTLEMENT_STATUS");
        this.statusOptions = useMapMasterTypeToOptionAlt(response).filter(
          item => status.includes(trimSpaceToUnderscore(item.value))
        );
      } finally {
        this.loading.status = false;
      }
    },
    handleReset() {
      const form = this.$refs.form as FormModel | null;
      if (!form) {
        return;
      }

      form.resetFields();
      this.state.unitCode = undefined;
      this.state.settlementDate = null;
      this.getCustomerList();
      this.getTruckList();
    },
    onChangeCustomer(value?: LabelInValue) {
      if (!value) {
        this.getCustomerList();
      }
    },
    onChangeUnit(value?: LabelInValue[]) {
      if (!value || value.length === 0) {
        this.getTruckList();
      }
    },
  },
});
