var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_settlement_detail_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            "label-align": "left",
            model: _vm.state,
            "wrapper-col": { span: 8 },
            "label-col": { span: 6 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_ujo_number"), prop: "ujo" } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_ujo_number")
                          }),
                          "allow-clear": "",
                          "data-testid":
                            "trucking-settlement-detail-report-ujo-number"
                        },
                        model: {
                          value: _vm.state.ujo,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "ujo", $$v)
                          },
                          expression: "state.ujo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_shipment_id"),
                        prop: "shipment"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_shipment_id")
                          }),
                          "allow-clear": "",
                          "data-testid":
                            "trucking-settlement-detail-report-shipment-id"
                        },
                        model: {
                          value: _vm.state.shipment,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "shipment", $$v)
                          },
                          expression: "state.shipment"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_customer"), prop: "customer" }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "label-in-value": "",
                          options: _vm.customerOptions,
                          loading: _vm.loading.customer,
                          "filter-option": false,
                          "show-search": "",
                          "allow-clear": "",
                          "data-testid":
                            "trucking-settlement-detail-report-customer",
                          "dropdown-match-select-width": false,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_customer")
                          })
                        },
                        on: {
                          search: _vm.onSearchCustomer,
                          change: _vm.onChangeCustomer
                        },
                        model: {
                          value: _vm.state.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "customer", $$v)
                          },
                          expression: "state.customer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_settlement_date"),
                        prop: "settlementDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          "allow-clear": "",
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ],
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "data-testid":
                            "trucking-settlement-detail-report-settlement-date"
                        },
                        model: {
                          value: _vm.state.settlementDate,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "settlementDate", $$v)
                          },
                          expression: "state.settlementDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "label-in-value": "",
                          options: _vm.truckOptions,
                          loading: _vm.loading.unitCode,
                          "filter-option": false,
                          "show-search": "",
                          "allow-clear": "",
                          mode: "multiple",
                          "data-testid":
                            "trucking-settlement-detail-report-unit-code",
                          "dropdown-match-select-width": false,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_unit_code")
                          })
                        },
                        on: {
                          search: _vm.onSearchTruck,
                          change: _vm.onChangeUnit
                        },
                        model: {
                          value: _vm.state.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "unitCode", $$v)
                          },
                          expression: "state.unitCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status"), prop: "status" } },
                    [
                      _c("a-select", {
                        attrs: {
                          options: _vm.statusOptions,
                          loading: _vm.loading.status,
                          "allow-clear": "",
                          "data-testid":
                            "trucking-settlement-detail-report-status",
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_status")
                          })
                        },
                        model: {
                          value: _vm.state.status,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "status", $$v)
                          },
                          expression: "state.status"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "data-testid":
                              "trucking-settlement-detail-report-reset"
                          },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "data-testid":
                              "trucking-settlement-detail-report-find",
                            loading: _vm.loading.find,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  "data-source": _vm.dataSource,
                  columns: _vm.columns,
                  size: "small",
                  pagination: {
                    showTotal: function() {
                      return _vm.$t("lbl_total_items", {
                        total: _vm.dataReport.totalElements
                      })
                    },
                    total: _vm.dataReport.totalElements,
                    showSizeChanger: true,
                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                    current: _vm.pagination.page,
                    defaultPageSize: _vm.pagination.limit
                  },
                  "row-class-name": function(_record, index) {
                    return index % 2 ? "bg-white" : "bg-gray-light"
                  },
                  scroll: { x: 2900 },
                  loading: _vm.loading.find,
                  "data-testid": "trucking-settlement-detail-report-table"
                },
                on: { change: _vm.onChangeTable }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-statistic", {
                attrs: {
                  title: _vm.$t("lbl_total"),
                  value: _vm.dataReport.total
                },
                scopedSlots: _vm._u([
                  {
                    key: "formatter",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    "data-testid": "trucking-settlement-detail-report-download"
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }